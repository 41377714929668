html {
  font-size: 62.5%; }

body {
  margin: 0;
  background: #fff;
  color: #000;
  font-family: 'Work Sans', sans-serif;
  font-weight: 300; }

.wrapper {
  max-width: 1180px;
  margin: 0 auto; }

.grid {
  display: flex;
  flex-wrap: wrap; }
  @media (min-width: 768px) {
    .grid {
      flex-wrap: nowrap; } }
  .grid .col {
    flex: 1;
    padding: 0 16px; }

.text p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.7;
  margin: 20px 0; }

.text--offer p {
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.3;
  text-align: center; }

p.headline {
  font-size: 48px;
  font-size: 4.8rem;
  margin-top: 0;
  margin-bottom: 30px;
  font-family: 'Libre Baskerville', serif; }
  p.headline:after {
    background: #c87532;
    height: 6px;
    display: block;
    content: "";
    max-width: 150px;
    margin: 12px 0; }

h2.section-title {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: 300;
  text-transform: uppercase;
  margin: 20px 0; }

.header {
  background: #0a163b; }
  .header__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0; }
    @media (max-width: 575px) {
      .header__top {
        flex-wrap: wrap; } }
  .header__logo {
    width: 257px; }
    @media (max-width: 575px) {
      .header__logo {
        margin: 0 auto;
        padding-bottom: 10px; } }
  @media (max-width: 575px) {
    .header__nav {
      margin: 0 auto; } }
  .header__nav ul {
    padding: 0;
    list-style: none; }
    @media (max-width: 575px) {
      .header__nav ul {
        text-align: center; } }
    .header__nav ul li {
      display: inline;
      margin: 16px 32px; }
      @media (max-width: 575px) {
        .header__nav ul li {
          margin: 8px 8px; } }
      .header__nav ul li a {
        font-size: 12px;
        font-size: 1.2rem;
        color: #fff;
        text-decoration: none;
        text-transform: uppercase;
        padding-bottom: 10px; }
        .header__nav ul li a:hover {
          border-bottom: solid 1px #c87532; }
  .header .hero-claim {
    background: url(src/images/doug_no_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    padding-left: 10px;
    box-sizing: border-box;
    padding-top: 30px;
    background-position: bottom center; }
    @media (min-width: 576px) {
      .header .hero-claim {
        height: 750px;
        padding-top: 220px;
        background-position: bottom right; } }
  @media (max-width: 575px) {
    .header .hero-text {
      transform: translateY(220px);
      color: #000;
      margin-bottom: 250px;
      text-align: center; }
      .header .hero-text h1, .header .hero-text p {
        color: #000;
        font-size: 25px;
        font-size: 2.5rem; } }
  .header__image {
    background: url(src/images/main_banner_bg.jpg);
    background-size: cover;
    color: #fff; }
    .header__image img {
      max-width: 100%; }
    .header__image h1 {
      font-family: 'Libre Baskerville', serif;
      color: #fff;
      margin-bottom: 10px;
      margin-top: 0px; }
      @media (min-width: 576px) {
        .header__image h1 {
          max-width: 70%;
          font-size: 52px;
          font-size: 5.2rem; } }
    .header__image p {
      font-family: 'Libre Baskerville', serif;
      font-size: 36px;
      font-size: 3.6rem;
      font-style: italic;
      margin: 10px 0; }
      @media (min-width: 576px) {
        .header__image p {
          max-width: 55%;
          font-size: 52px;
          font-size: 5.2rem; } }
    .header__image .btn {
      margin-top: 15px;
      display: inline-block; }

.btn {
  display: block;
  max-width: 200px;
  background: #c87532;
  font-size: 14px;
  font-size: 1.4rem;
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  padding: 20px 40px;
  text-decoration: none;
  border-radius: 4px;
  -webkit-appearance: none; }
  .btn:hover {
    text-decoration: underline; }
  .btn--center {
    margin: 0 auto; }

.center {
  text-align: center; }

section.section {
  margin-top: 40px;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto; }

.about {
  align-items: center;
  background-position: left bottom;
  min-height: 680px;
  position: relative; }
  .about .col {
    flex: 1 1 50%; }
  @media (min-width: 768px) {
    .about .pic img {
      position: absolute;
      bottom: 0;
      z-index: -1; } }
  @media (min-width: 768px) {
    .about h2 {
      margin-top: 40px; }
    .about .txt {
      background-color: rgba(255, 255, 255, 0.8); } }
  @media (min-width: 1200px) {
    .about .txt {
      background-color: transparent; } }
  .about img {
    max-width: 100%; }

.offer {
  background: #0a163b;
  color: #fff;
  margin-top: 0 !important;
  padding-top: 20px;
  padding-bottom: 40px; }
  .offer .col {
    flex: 1 1 280px; }
  .offer__item {
    text-align: center; }
    .offer__item h3 {
      font-size: 18px;
      font-size: 1.8rem;
      text-transform: uppercase; }
    .offer__item p {
      font-size: 14px;
      font-size: 1.4rem;
      line-height: 1.7; }
    .offer__item img {
      max-width: 100%; }
  .offer__description {
    padding-bottom: 40px; }
    .offer__description p {
      font-size: 18px;
      font-size: 1.8rem;
      line-height: 1.7;
      text-align: center; }

.gallery {
  padding-top: 20px;
  padding-bottom: 40px;
  border-bottom: solid 1px #eaeaea; }

.slider-container {
  position: relative;
  overflow: hidden;
  margin-bottom: 40px; }
  .slider-container .next, .slider-container .prev {
    outline: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: transparent;
    border: none;
    cursor: pointer; }
    .slider-container .next i.fas, .slider-container .prev i.fas {
      font-size: 60px;
      color: #fff;
      opacity: 0.8; }
  .slider-container .prev {
    left: 50px; }
  .slider-container .next {
    right: 50px; }

.feedback {
  background: url(src/images/ic_quotation_mark.svg) no-repeat;
  text-align: center; }
  .feedback p {
    font-size: 14px;
    font-size: 1.4rem;
    line-height: 1.7;
    padding: 0 20%; }
  .feedback .slick-dots {
    list-style: none;
    padding: 0; }
    .feedback .slick-dots li {
      display: inline;
      margin: 0 10px; }
    .feedback .slick-dots .slick-active .custom-pager {
      background: #fff; }
    .feedback .slick-dots .custom-pager {
      outline: none;
      background: #c87532;
      width: 16px;
      height: 16px;
      padding: 0;
      border-radius: 16px;
      border: solid 3px #c87532;
      cursor: pointer; }

.contact {
  background: url(src/images/contact_bg.jpg);
  background-size: cover;
  background-position: center bottom;
  padding-top: 60px;
  margin-top: 0 !important;
  padding-bottom: 30px;
  color: #fff;
  min-height: 690px; }
  .contact .text p {
    font-size: 18px;
    font-size: 1.8rem; }
  .contact .col {
    flex: 1 1 580px; }
  .contact p.contact-details {
    font-size: 22px;
    font-size: 2.2rem; }

.form-contact {
  padding: 20px;
  color: #ccc;
  font-size: 14px; }

.form-contact input[type=submit] {
  border: none; }
  @media (max-width: 575px) {
    .form-contact input[type=submit] {
      margin: 0 auto; } }

.form-contact input[type=text],
.form-contact input[type=email],
.form-contact textarea {
  width: 90%;
  padding: 6px;
  background: transparent;
  border: none;
  border-bottom: solid 1px #fff;
  color: #fff;
  font-size: 16px; }

.form-contact input[type=text] {
  margin-bottom: 10px; }

div.wpcf7-mail-sent-ok {
  border: none;
  padding: 10px;
  background-color: #d7a92e;
  color: #fff; }

div.wpcf7-validation-errors {
  border: none;
  padding: 10px;
  background-color: #d7a92e;
  color: #fff; }

div.wpcf7-mail-sent-ng, div.wpcf7-aborted {
  border: none;
  padding: 10px;
  background-color: #b30808;
  color: #fff; }

.footer {
  background: #0a163b;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem; }

.copyright {
  background: #0a163b;
  padding: 10px 0;
  text-align: center; }

.socials-container {
  text-align: right;
  padding: 30px 0; }

@media (max-width: 576px) {
  .text p, .section-title, .headline, .offer__description p, .socials-container {
    text-align: center; }
  .section-title, p.headline {
    font-size: 25px;
    font-size: 2.5rem; }
  p.headline:after {
    margin: 16px auto; } }

.socials {
  display: inline-block;
  border-radius: 4px;
  width: 60px;
  height: 60px;
  background-color: #0a163b;
  background-repeat: no-repeat;
  background-position: center center; }
  .socials--fb {
    background-image: url(src/images/ic_facebook.svg); }
  .socials--ig {
    background-image: url(src/images/ic_instagram.svg); }
  .socials:hover {
    background-color: #c87532; }

.page-template .header__image {
  display: none; }
